import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { getFirestore, collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { uploadImageToFirebase } from '../utils/firebaseStorageUtils';
import { uploadFileDirectly } from '../utils/uploadFileDirectly';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { useNavigate } from 'react-router-dom';

const PostForm = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [description, setDescription] = useState('');
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [newFolderIsPrivate, setNewFolderIsPrivate] = useState(false);
  const [newFolderImage, setNewFolderImage] = useState(null);
  const [metadataError, setMetadataError] = useState(false); // Add state for metadata error

  const db = getFirestore();

  useEffect(() => {
    const fetchFolders = async () => {
      if (currentUser) {
        const q = query(collection(db, 'folders'), where('ownerId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        const foldersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFolders(foldersList);
      }
    };
    fetchFolders();
  }, [currentUser, db]);

  const fetchMetadata = async (url) => {
    try {
      const getMetadata = httpsCallable(functions, 'extractMetadata');
      const result = await getMetadata({ url });
      console.log(result.data);
      const { title, imageUrl, description } = result.data;

      setTitle(title || '');
      setImageUrl(imageUrl || '');
      setDescription(description || '');

      return { title, imageUrl, description };
    } catch (error) {
      console.error('Error fetching metadata:', error.message);
      setMetadataError(true); // Set error flag
      return { title: '', imageUrl: '', description: '' };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const metadata = await fetchMetadata(url);

      let postImageUrl = '';
      if (metadata.imageUrl) {
        try {
          postImageUrl = await uploadImageToFirebase(metadata.imageUrl);
        } catch (error) {
          console.error('Error uploading image:', error.message);
          // Check for a CORS error or any specific error
          if (error.message.includes('CORS')) {
            postImageUrl = 'https://tellos.co/tellos-logo.png'; // Replace with your default image URL
          } else {
            throw error; // Re-throw if it's a different type of error
          }
        }
      }

      let folderId = selectedFolderId;

      if (isCreatingNewFolder) {
        let folderImageUrl = '';
        if (newFolderImage) {
          try {
            folderImageUrl = await uploadFileDirectly(newFolderImage);
          } catch (error) {
            console.error('Error uploading folder image:', error.message);
            if (error.message.includes('CORS')) {
              folderImageUrl = 'https://tellos.co/tellos-logo.png'; // Replace with your default folder image URL
            } else {
              throw error; // Re-throw if it's a different type of error
            }
          }
        }

        const newFolder = {
          name: newFolderName,
          isPrivateFolder: newFolderIsPrivate,
          ownerId: currentUser.uid,
          timestamp: serverTimestamp(),
          imageUrl: folderImageUrl,
        };

        const folderRef = await addDoc(collection(db, 'folders'), newFolder);
        folderId = folderRef.id;
      }

      const postData = {
        url,
        title: metadata.title || title, // Use user-provided title if metadata title is missing
        imageUrl: postImageUrl || 'https://tellos.co/tellos-logo.png', // Default if no image URL from metadata
        description: metadata.description || '',
        caption: caption || '',
        timestamp: serverTimestamp(),
        ownerUid: currentUser.uid,
        folderId,
        featured: false,
      };

      const postRef = await addDoc(collection(db, 'posts'), postData);

      // Add the post to the specific folder's posts subcollection
      await addDoc(collection(db, `folders/${folderId}/posts`), {
        postId: postRef.id,
        userId: currentUser.uid,
      });

      // Create an activity entry in the user's notifications
      await addDoc(collection(db, `activity/${currentUser.uid}/user-notifications`), {
        senderUid: currentUser.uid,
        timestamp: serverTimestamp(),
        type: 2,
      });

      setUrl('');
      setTitle('');
      setImageUrl('');
      setDescription('');
      setCaption('');
      setSelectedFolderId('');
      setIsCreatingNewFolder(false);
      setNewFolderName('');
      setNewFolderIsPrivate(false);
      setNewFolderImage(null);
      setMetadataError(false); // Reset error flag after submission
    } catch (error) {
      console.error('Error adding document:', error);
    } finally {
      setLoading(false);
      navigate('/');
    }
  };

  const handleFolderOptionChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'new') {
      setIsCreatingNewFolder(true);
      setSelectedFolderId(''); // Clear selected folder ID when creating a new folder
    } else {
      setIsCreatingNewFolder(false);
      setSelectedFolderId(selectedValue);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>URL:</label>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          required
        />
      </div>

      <div>
        <label>Folder:</label>
        <select value={selectedFolderId} onChange={handleFolderOptionChange}>
          <option value="">Select Folder</option>
          {folders.map(folder => (
            <option key={folder.id} value={folder.id}>{folder.name}</option>
          ))}
          <option value="new">Create New Folder</option>
        </select>
      </div>

      {/* Conditionally render the Title input field if metadata cannot be retrieved */}
      {metadataError && (
        <div>
          <label>Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
            required
          />
        </div>
      )}

      <div>
        <label>Caption:</label>
        <textarea
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          placeholder="Add a caption"
        />
      </div>

      {isCreatingNewFolder && (
        <div>
          <label>New Folder Name:</label>
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            required
          />
          <label>Private Folder:</label>
          <input
            type="checkbox"
            checked={newFolderIsPrivate}
            onChange={(e) => setNewFolderIsPrivate(e.target.checked)}
          />
          <label>Folder Image:</label>
          <input
            type="file"
            onChange={(e) => setNewFolderImage(e.target.files[0])}
          />
        </div>
      )}

      <button type="submit" disabled={loading}>
        {loading ? 'Loading...' : 'Create Post'}
      </button>
    </form>
  );
};

export default PostForm;