// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAUmWzmV2GyOEkCsaIbEiMhOPgps63fpWg",
  authDomain: "tellos-2ba21.firebaseapp.com",
  projectId: "tellos-2ba21",
  storageBucket: "tellos-2ba21.appspot.com",
  messagingSenderId: "5482086564",
  appId: "1:5482086564:web:fbcd353fe818be60f46631",
  measurementId: "G-YML4FRSKV4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

const firestore = getFirestore(app);

const auth = getAuth(app);

const storage = getStorage(app);

const functions = getFunctions(app);

export { db, collection, query, where, getDocs, firestore, auth, storage, functions };
