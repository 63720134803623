import React, { useEffect, useState } from 'react';
import { useAuth } from './context/AuthContext';
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import { collection, query, where, getDocs, orderBy, limit, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import './UserProfile.css';
import FetchUserCollection from './FetchUserCollection';
import ActionButton from './components/ActionButton';
import SocialMediaLinks from './components/SocialMediaLinks';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {
  MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody,
  MDBCardImage, MDBTypography, MDBCardTitle, MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdb-react-ui-kit';
import { FaArrowRight } from 'react-icons/fa';

function UserProfile() {
  const { currentUser } = useAuth();
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [folders, setFolders] = useState([]);
  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          setUser(doc.data());
          setUserId(doc.id);
        });
      }
    };
    fetchUser();
  }, [username]);

  useEffect(() => {
    const fetchFeaturedPosts = async () => {
      const postsRef = collection(db, 'posts');
      const q = query(postsRef, where('ownerUid', '==', userId), where('featured', '==', true));
      const querySnapshot = await getDocs(q);
      setFeaturedPosts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchRecentPosts = async () => {
      if (userId) {
        const postsRef = collection(db, 'posts');
        const q = query(postsRef, where('ownerUid', '==', userId), orderBy('timestamp', 'desc'), limit(9));
        const querySnapshot = await getDocs(q);
        const postsList = querySnapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        setRecentPosts(postsList);
      }
    };

    const fetchFoldersWithPostCount = async () => {
      if (userId) {
        const foldersRef = collection(db, 'folders');
        const foldersQuery = query(foldersRef, where('ownerId', '==', userId));
        const foldersSnapshot = await getDocs(foldersQuery);
        const foldersWithCounts = await Promise.all(foldersSnapshot.docs.map(async doc => {
          const folderId = doc.id;
          const postsRef = collection(db, `folders/${folderId}/posts`);
          const postsSnapshot = await getDocs(postsRef);
          return {
            id: folderId,
            name: doc.data().name,
            imageUrl: doc.data().imageUrl,
            isPrivateFolder: doc.data().isPrivateFolder,
            postCount: postsSnapshot.size  // Number of posts in this folder
          };
        }));
        setFolders(foldersWithCounts);
      }
    };

    if (userId) {
      fetchFeaturedPosts();
      fetchRecentPosts();
      fetchFoldersWithPostCount();
    }
  }, [userId]);

  const ensureAbsoluteUrl = (url) => {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  };

  const handleFeatureToggle = async (postId, isFeatured) => {
    try {
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, { featured: !isFeatured });
      window.location.reload(); // Refresh the page after toggling feature status
    } catch (error) {
      console.error('Error toggling feature status:', error);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      const postRef = doc(db, 'posts', postId);
      await deleteDoc(postRef);
      window.location.reload(); // Refresh the page after deleting a post
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handlePrivacyToggle = async (folderId, isPrivate) => {
    try {
      const folderRef = doc(db, 'folders', folderId);
      await updateDoc(folderRef, { isPrivateFolder: !isPrivate });
      window.location.reload(); // Refresh the page after toggling privacy status
    } catch (error) {
      console.error('Error toggling privacy status:', error);
    }
  };

  if (!user) return <div>Loading...</div>;
  if (!user.username) return <div>No such user with username: {username}</div>;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <MDBContainer className="py-5">
      <MDBRow className="justify-content-center">
        <MDBCol md="10" lg="10" xl="10">
          <MDBCard className="text-center">
            <div className="rounded-top p-4">
              <MDBTypography tag="h3" className="font-weight-bold">{user.fullname}</MDBTypography>
              <MDBCardImage
                src={user.profileImageUrl || '/tellos-logo.png'}
                alt={user.fullname}
                className="rounded-circle img-thumbnail mb-3"
                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
              />
              <MDBCardText className="text-muted mb-4" style={{ fontWeight: "bold" }}>@{user.username}</MDBCardText>
              <SocialMediaLinks user={user} />
              <MDBCardText className="mb-4 h5"><FetchUserCollection userId={userId} /></MDBCardText>
              <MDBCardText>{user.bio || 'Developer and avid taco lover.'}</MDBCardText>
              <MDBRow><ActionButton user={currentUser} username={username} /></MDBRow>
            </div>
            <MDBCardBody>
              <div className="center-tabs">
                <MDBTabs className='mb-3'>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                      <i className="fa-solid fa-link"></i>
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                      <i className="fa-regular fa-folder"></i>
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              </div>
              <MDBTabsContent>
                <MDBTabsPane open={basicActive === 'tab1'}>
                  <MDBRow>
                    <div className="d-flex justify-content-center align-items-center">
                      <MDBTypography tag="h4" className="font-weight-bold">Featured Links</MDBTypography>
                      <a href={`/u/${user.username}/featured`} style={{ color: "inherit", marginTop: "-15px", marginLeft: "10px" }}>
                        <FaArrowRight style={{ cursor: "pointer" }} />
                      </a>
                    </div>
                    <div className="slider-container">
                      <Slider {...settings}>
                        {featuredPosts.map((post) => (
                          <div key={post.id} style={{ padding: "10px" }} className="col-4 col-sm-4 col-md-3 mb-4 position-relative">
                            <a href={ensureAbsoluteUrl(post.url) || '#'} target="_blank" rel="noreferrer">
                              <img src={post.imageUrl || '/tellos-logo.png'} alt={post.title} className="card-header-image" />
                            </a>
                            <p className="post-card-title">{post.title}</p>
                            <p className="post-card-caption">{post.caption || 'No description available.'}</p>

                            {/* Show dropdown only if the post belongs to the current user */}
                            {currentUser && currentUser.uid === post.ownerUid && (
                              <MDBDropdown className="position-absolute" style={{ top: '90px', right: '5px' }}>
                                <MDBDropdownToggle tag="a" className="nav-link">
                                  <MDBIcon fas icon="ellipsis-v" />
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                  <MDBDropdownItem link onClick={() => handleFeatureToggle(post.id, post.featured)}>
                                    {post.featured ? 'Unfeature' : 'Feature'}
                                  </MDBDropdownItem>
                                  <MDBDropdownItem link onClick={() => handleDeletePost(post.id)}>
                                    Delete
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </MDBRow>
                  <MDBRow className="d-flex flex-wrap justify-content-center" style={{ marginTop: "20px" }}>
                    <div className="d-flex justify-content-center align-items-center">
                      <MDBTypography tag="h4" className="font-weight-bold">Recent Links</MDBTypography>
                      <a href={`/u/${user.username}/links`} style={{ color: "inherit", marginTop: "-15px", marginLeft: "10px" }}>
                        <FaArrowRight style={{ cursor: "pointer" }} />
                      </a>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                      {recentPosts.map((post) => (
                        <MDBCol key={post.id} xs="12" sm="6" md="4" lg="3" className="mb-4">
                          <MDBCard className="position-relative">
                            <a href={ensureAbsoluteUrl(post.url) || '#'} target="_blank" rel="noreferrer">
                              <MDBCardImage
                                src={post.imageUrl || '/tellos-logo.png'}
                                alt={post.title}
                                className="card-header-image"
                              />
                            </a>
                            <MDBCardBody>
                              <MDBCardTitle className="post-card-title">{post.title}</MDBCardTitle>
                              <MDBCardText className="post-card-caption">{post.caption || 'No description available.'}</MDBCardText>

                              {/* Show dropdown only if the post belongs to the current user */}
                              {currentUser && currentUser.uid === post.ownerUid && (
                                <MDBDropdown className="position-absolute" style={{ top: '90px', right: '-10px' }}>
                                  <MDBDropdownToggle tag="a" className="nav-link">
                                    <MDBIcon fas icon="ellipsis-v" />
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => handleFeatureToggle(post.id, post.featured)}>
                                      {post.featured ? 'Unfeature' : 'Feature'}
                                    </MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => handleDeletePost(post.id)}>
                                      Delete
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown>
                              )}
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      ))}
                    </div>
                  </MDBRow>
                </MDBTabsPane>
                <MDBTabsPane open={basicActive === 'tab2'}>
                  <MDBTypography tag="h4" className="font-weight-bold">
                    Link Folders
                  </MDBTypography>
                  <div className="d-flex flex-wrap justify-content-center">
                    {folders.map(folder => (
                      <MDBCol key={folder.id} xs="12" sm="6" md="4" lg="3" className="mb-4">
                        <MDBCard>
                          <MDBCardBody>
                            <a href={`/f/${folder.id}`}>
                              <MDBCardImage src={folder.imageUrl || '/tellos-logo.png'} alt={folder.name} className="card-header-image" />
                            </a>
                            <MDBCardTitle className="post-card-title">{folder.name}</MDBCardTitle>
                            <MDBCardText className="post-card-caption">
                              {folder.postCount} Links
                            </MDBCardText>

                            {/* Show dropdown only if the folder belongs to the current user */}
                            {currentUser && currentUser.uid === userId && (
                              <MDBDropdown className="position-absolute" style={{ top: '90px', right: '5px' }}>
                                <MDBDropdownToggle tag="a" className="nav-link">
                                  <MDBIcon fas icon="ellipsis-v" />
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                  <MDBDropdownItem link onClick={() => handlePrivacyToggle(folder.id, folder.isPrivateFolder)}>
                                    {folder.isPrivateFolder ? 'Make Public' : 'Make Private'}
                                  </MDBDropdownItem>
                                </MDBDropdownMenu>
                              </MDBDropdown>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    ))}
                  </div>
                </MDBTabsPane>
              </MDBTabsContent>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default UserProfile;
