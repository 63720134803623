import React, { useEffect, useState } from 'react';
import { db, collection, query, getDocs } from './firebase';  // Adjust path as needed
import { where } from "firebase/firestore";  
import {
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBCardTitle,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBCardText
} from 'mdb-react-ui-kit';

const FetchFolderPosts = ({ folderId }) => {
  const [data, setData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, 'folders/' + folderId + '/posts'));
        const querySnapshot = await getDocs(q);

        const items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setData(items);

        // Extract postIds from the items
        const postIds = items.map(item => item.postId);

        if (postIds.length > 0) {
          // Fetch the posts that match the postIds
          const postsQuery = query(
            collection(db, 'posts'),
            where('__name__', 'in', postIds)
          );
          const postsSnapshot = await getDocs(postsQuery);

          const postsData = postsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setPosts(postsData);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [folderId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message} {data}</div>;

  return (
    <MDBRow style={{ marginTop: "20px" }}>
      <MDBTypography tag="h4" className="font-weight-bold">Folder</MDBTypography>
      <div className="d-flex flex-wrap justify-content-center">
        {posts.map((post) => (
          <MDBCol key={post.id} md="3" sm="4" className="col-4 mb-4">
            <MDBCard>
              <a href={post.url || '#'} target="_blank" rel="noreferrer">
                <MDBCardImage
                  src={post.imageUrl || '/tellos-logo.png'}
                  alt={post.title}
                  className="card-header-image"
                />
              </a>
              <MDBCardBody>
                <MDBCardTitle className="post-card-title">{post.title}</MDBCardTitle>
                <MDBCardText className="post-card-caption">{post.caption || 'No description available.'}</MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </div>
    </MDBRow>
  );
};

export default FetchFolderPosts;
