import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import './SocialMediaLinks.css';

function SocialMediaLinks({ user }) {
  const icons = {
    instagram: faInstagram,
    twitter: faTwitter,
    github: faGithub,
    personal: faGlobe // This assumes you have a generic link icon or use another suitable one
  };

  const socialMedia = [
    { name: 'instagram', url: `https://instagram.com/${user['social-instagram']}`, icon: icons.instagram },
    { name: 'twitter', url: `https://twitter.com/${user['social-twitter']}`, icon: icons.twitter },
    { name: 'github', url: `https://github.com/${user['social-github']}`, icon: icons.github },
    { name: 'personal', url: user['social-personal'], icon: icons.personal }
  ];

  return (
    <div className="social-media-links mb-4">
      {socialMedia.map((media) => {
        if (user[`social-${media.name}`]) {
          return (
            <a key={media.name} href={media.url} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={media.icon} size="lg" />
            </a>
          );
        }
        return null;
      })}
    </div>
  );
}

export default SocialMediaLinks;