import React, { useState, useEffect } from 'react';
import { firestore, auth, storage } from '../firebase'; // Adjust the path as needed
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import BackArrow from './BackArrow';

function EditProfile() {
  const [currentUserId, setCurrentUserId] = useState(null);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    username: '',
    email: '',
    bio: '',
    fullname: '',
    profileImageUrl: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(''); // State for image preview

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUserId(user.uid);

        // Fetch user profile data
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setProfileData(userDoc.data());
          setImagePreview(userDoc.data().profileImageUrl); // Set initial image preview
        }
      } else {
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file)); // Create a URL for the image preview
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentUserId) {
      try {
        let profileImageUrl = profileData.profileImageUrl;

        // If a new image is selected, upload it to Firebase Storage
        if (imageFile) {
          const imageRef = ref(storage, `profileImages/${currentUserId}`);
          await uploadBytes(imageRef, imageFile);
          profileImageUrl = await getDownloadURL(imageRef);
        }

        // Update profile data with the new image URL if it was uploaded
        const userDocRef = doc(firestore, 'users', currentUserId);
        await updateDoc(userDocRef, { ...profileData, profileImageUrl });

        // Navigate to the home page after saving
        navigate('/');
      } catch (error) {
        console.error('Error updating profile:', error);
        alert('Failed to update profile. Please try again.');
      }
    }
  };

  if (!currentUserId) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <BackArrow />
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Profile Image:
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </label>
        </div>
        {/* Image Preview */}
        <div>
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Profile Preview"
              style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' }}
            />
          ) : (
            <p>No profile picture available</p>
          )}
        </div>
        <div>
          <label>
            Display Name:
            <input
              type="text"
              name="username"
              value={profileData.username}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Full Name:
            <input
              type="text"
              name="fullname"
              value={profileData.fullname}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={profileData.email}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Bio:
            <textarea
              name="bio"
              value={profileData.bio}
              onChange={handleChange}
            />
          </label>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}

export default EditProfile;
